import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../css/AstroServices1.css'; // Ensure the path is correct
import { Helmet } from 'react-helmet';
const App = () => {
    const [astrologers, setAstrologers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchAstrologers = async () => {
            setLoading(true);

            try {
                const response = await fetch('https://www.divvyajyoti.com/api/astrologerServicePc.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        limit: 10000 // Fetch all data in one go
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const allAstrologers = await response.json();

                // Filter out duplicates if needed
                setAstrologers(prev => {
                    const allAstrologersWithPrev = [...prev, ...allAstrologers];
                    const uniqueAstrologers = Array.from(new Map(allAstrologersWithPrev.map(a => [a.phone_number, a])).values());
                    return uniqueAstrologers;
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAstrologers();
    }, []);

    const renderArrayValues = (values) => {
        if (values) {
            const skillsArray = Array.isArray(values) ? values : tryParseJSON(values);

            // Slice the array to get the first 3 items
            const limitedArray = skillsArray ? skillsArray.slice(0, 3) : [];

            if (limitedArray.length > 0) {
                return (
                    <div className="skills-container">
                        {limitedArray.map((value, index) => (
                            <div className="box" key={index}>
                                <span className="language">
                                    {value.trim()}
                                </span>
                            </div>
                        ))}
                    </div>
                );
            }
        }
        return null;
    };

    const tryParseJSON = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return null;
        }
    };

    const handleChat = () => {
        console.log('Chat clicked');
        window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

    };

    const handleAudioCall = () => {
        console.log('Audio Call clicked');
        window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

    };

    const handleVideoCall = () => {
        console.log('Video Call clicked');
        window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

    };

    const handleCardClick = (phone_number) => {
        navigate('/astrologer-profile', { state: { phone_number: phone_number } });
    };

    return (
        <div className="astrologer-grid-container">
            {loading ? (
                <p>Loading...</p>
            ) : (
                astrologers.map((astrologer) => {
                    const isChatAvailable = astrologer.onlineStatus === 'online' && astrologer.selected_options.includes('Chat');
                    const isAudioCallAvailable = astrologer.onlineStatus === 'online' && astrologer.selected_options.includes('Audio Call');
                    const isVideoCallAvailable = astrologer.onlineStatus === 'online' && astrologer.selected_options.includes('Video Call');

                    return (
                        
                        <div
                            className="astrologer-card"
                            key={astrologer.phone_number}
                            onClick={() => handleCardClick(astrologer.phone_number)}
                        >
    <Helmet>
        <title>Astro Services | Divvya Jyoti</title>
        <meta name="description" content="Divvya Jyoti offers expert astrology consultations and spiritual guidance. Learn more about our services, team, and unique mobile app for connecting with renowned astrologers." />
        <meta name="keywords" content="Divvya Jyoti, astrology consulting, spiritual guidance, Vedic astrology, Western astrology, Chinese astrology, mobile app, puja services, horoscopes" />
        <meta property="og:title" content="About Us | Divvya Jyoti" />
        <meta property="og:description" content="Discover how Divvya Jyoti connects seekers with profound wisdom through expert astrology consultations and spiritual guidance. Learn more about our services and unique mobile app." />
        <meta property="og:image" content="/path-to-image.jpg" /> {/* Adjust this path to your image */}
        <meta property="og:url" content="https://yourwebsite.com/about-us" /> {/* Update with your actual URL */}
      </Helmet>
                            <div className="astrologer-details">
                                <img
                                    className="astrologer-image"
                                    src={`https://www.divvyajyoti.com/astrologerDp/${astrologer.dp_url}`}
                                    alt={astrologer.dp_name || astrologer.name}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'path/to/fallback-image.jpg'; // Replace with the correct path to your fallback image
                                    }}
                                />
                                {/* Render star rating */}
                                <div className="star-rating">
                                    {[...Array(5)].map((_, index) => (
                                        <span
                                            key={index}
                                            className={`star ${index < astrologer.ratting ? 'filled' : ''}`}
                                        >
                                            &#9733; {/* Unicode star character */}
                                        </span>
                                    ))}
                                </div>
                                <div className="astrologer-info">
                                    <h2>{astrologer.dp_name || astrologer.name}</h2>
                                    <p style={{ color: 'darkblue' }}>{astrologer.experience} years of experience</p>
                                    <div>
                                        {renderArrayValues(astrologer.language)}
                                    </div>
                                    <div>
                                        {renderArrayValues(astrologer.skills)}
                                    </div>
                                </div>
                            </div>

                            <div className="actions-container">
                                <button
                                    onClick={handleChat}
                                    disabled={!isChatAvailable}
                                    className={`action-button1 ${!isChatAvailable ? 'disabled' : ''}`}
                                >
                                    {isChatAvailable ? (
                                        <>
                                            Chat<br />
                                            ₹{(parseFloat(astrologer.chatCharge) + parseFloat(astrologer.chatPlatformFee)).toFixed(0)} /min
                                        </>
                                    ) : (
                                        <>
                                            Chat<br />
                                            Not Available
                                        </>
                                    )}
                                </button>
                                <button
                                    onClick={handleAudioCall}
                                    disabled={!isAudioCallAvailable}
                                    className={`action-button1 ${!isAudioCallAvailable ? 'disabled' : ''}`}
                                >
                                    {isAudioCallAvailable ? (
                                        <>
                                            Audio Call<br />
                                           ₹{(parseFloat(astrologer.callCharge) + parseFloat(astrologer.callPlatformFee)).toFixed(0)} /min
                                        </>
                                    ) : (
                                        <>
                                            Audio Call<br />
                                            Not Available
                                        </>
                                    )}
                                </button>
                                <button
                                    onClick={handleVideoCall}
                                    disabled={!isVideoCallAvailable}
                                    className={`action-button1 ${!isVideoCallAvailable ? 'disabled' : ''}`}
                                >
                                    {isVideoCallAvailable ? (
                                        <>
                                            Video Call<br />
                                           ₹{(parseFloat(astrologer.vedioCallCharge) + parseFloat(astrologer.vedioCallPlatformFee)).toFixed(0)} /min
                                        </>
                                    ) : (
                                        <>
                                            Video Call<br />
                                            Not Available
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default App;
