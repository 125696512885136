import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Use useLocation to access state
import '../css/AstrologerProfile.css'; // Ensure you import the CSS file
import { Helmet } from 'react-helmet';
const AstrologerProfile = () => {
  const [astrologer, setAstrologer] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const location = useLocation(); // Get the location object to access state
  const { phone_number } = location.state || {}; // Extract phone_number from state

  useEffect(() => {
    if (phone_number) {
      console.log('phone number', phone_number);

      // Fetch astrologer details based on phone number
      fetch('https://www.divvyajyoti.com/api/astrologerProfiePc.php', {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number }), // Send phone_number as JSON
      })
        .then(response => response.json())
        .then(data => {
          setAstrologer(data);
          setLoading(false); // Set loading to false once data is fetched
        })
        .catch(error => {
          console.error('Error fetching astrologer details:', error);
          setLoading(false); // Set loading to false even if there's an error
        });
    }
  }, [phone_number]);

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Loading...</p>
      </div>
    );
  }

  if (!astrologer) {
    return <p>No data available</p>; // Handle the case where no data is available
  }
  const renderArrayValues = (values) => {
    if (values) {
      // Convert to array if it's not already
      const skillsArray = Array.isArray(values) ? values : tryParseJSON(values);
  
      if (skillsArray && skillsArray.length > 0) {
        return (
          <div className="skills-container">
            {skillsArray.map((value, index) => (
              <div className="box" key={index}>
                <span className="language">
                  {value.trim()} {/* You can apply translation here if needed */}
                </span>
              </div>
            ))}
          </div>
        );
      }
    }
    return null;
  };
  const tryParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return null;
    }
  };
 
  const isOnline = astrologer.onlineStatus === 'online';
  const options = astrologer.selected_options || [];

  const handleChat = () => {
    // Handle chat initiation
    console.log('Initiate chat');
    window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

  };

  const handleAudioCall = () => {
    // Handle audio call initiation
    console.log('Initiate audio call');
    window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

  };

  const handleVideoCall = () => {
    // Handle video call initiation
    console.log('Initiate video call');
    window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');

  };
  
  
  return (
    <div className="astrologer-profile-container">

<Helmet>
        <title>Astrologer Profile | Divvya Jyoti</title>
        <meta name="description" content="Divvya Jyoti offers expert astrology consultations and spiritual guidance. Learn more about our services, team, and unique mobile app for connecting with renowned astrologers." />
        <meta name="keywords" content="Divvya Jyoti, astrology consulting, spiritual guidance, Vedic astrology, Western astrology, Chinese astrology, mobile app, puja services, horoscopes" />
        <meta property="og:title" content="About Us | Divvya Jyoti" />
        <meta property="og:description" content="Discover how Divvya Jyoti connects seekers with profound wisdom through expert astrology consultations and spiritual guidance. Learn more about our services and unique mobile app." />
        <meta property="og:image" content="/path-to-image.jpg" /> {/* Adjust this path to your image */}
        <meta property="og:url" content="https://yourwebsite.com/about-us" /> {/* Update with your actual URL */}
      </Helmet>
      <img
        src={`https://www.divvyajyoti.com/astrologerDp/${astrologer.dp_url}`}
        alt={astrologer.dp_name || astrologer.name}
        className="astrologer-profile-image"
      />
            {/* Render star rating */}
            <div className="star-rating">
        {[...Array(5)].map((_, index) => (
          <span
            key={index}
            className={`star ${index < astrologer.ratting ? 'filled' : ''}`}
          >
            &#9733; {/* Unicode star character */}
          </span>
        ))}
      </div>

      <h1>{astrologer.dp_name || astrologer.name}</h1>
      <p style={{color:'darkblue'}}>{astrologer.experience} yeras of experience</p>

      <div >
            {renderArrayValues(astrologer.language)}
      </div>
    
      <div>
            {renderArrayValues(astrologer.skills)}
      </div>  
      
       {/* Render buttons */}
       <div className="actions-container">
        <button
          onClick={handleChat}
          disabled={!isOnline || !options.includes('Chat')}
          className={`action-button ${!isOnline || !options.includes('Chat') ? 'disabled' : ''}`}
        >
          Chat<br/>
          Starting from ₹{((parseFloat(astrologer.chatCharge) + parseFloat(astrologer.chatPlatformFee)).toFixed(0)
          )}
        </button>
        <button
                 onClick={handleAudioCall}
                 disabled={!isOnline || !options.includes('Audio Call')}
                 className={`action-button ${!isOnline || !options.includes('Audio Call') ? 'disabled' : ''}`}
        >
                   Audio Call <br />
  Starting from ₹{((parseFloat(astrologer.callCharge) + parseFloat(astrologer.callPlatformFee)).toFixed(0)
  )}
            </button>

        <button
          onClick={handleVideoCall}
          disabled={!isOnline || !options.includes('Video Call')}
          className={`action-button ${!isOnline || !options.includes('Video Call') ? 'disabled' : ''}`}
        >
          Video Call <br/>
          Starting from ₹{((parseFloat(astrologer.vedioCallCharge) + parseFloat(astrologer.vedioCallPlatformFee)).toFixed(0)
  )}
        </button>
      </div>
    
          {/* Add more details about the astrologer here */}
    </div>
  );
};

export default AstrologerProfile;
