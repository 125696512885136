import React from 'react';
import './css/Footer.css'; // Ensure this CSS file is created
import facebook from './image/facebook.png'
import insta from './image/instagram.png'
import twitter from './image/twitter.png'
import logo from './image/logo.png'
const handleClick = () => {
  window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');
};

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
      <div className="footer-top">
        <button onClick={handleClick} className="download-button">
          Download Our Mobile<br />App Now
        </button>
      </div>
         <div className="footer-sectionlogo">
            <img className='logo' src={logo}/>
            <h4>Empowering Your Spiritual <br/>
            Journey with Expert Astrologers</h4>
            <h3>Address</h3>
            <h4>SAHA COMPLEX,<br/>
            SUBHAS AVENUE,<br/>GOPAL SUNDARI CHAK,<br/>RANAGHAT, PIN-741201
            </h4>
          </div>
        <div className="footer-section">
          
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="./astro-service">Astrological Services</a></li>
            <li><a href="./pooja">Book Puja</a></li>
            <li><a href="./contact-us">Contact Us</a></li>
            <li><a href="./PRIVACY-POLICY.html">Privacy Policy</a></li>
            <li><a href="./RefundPolicy">Refund Policy</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" />
            </a>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
