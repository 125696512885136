import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './css/Header.css';
import logo from './image/logo.png'; // Import the image from src

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const getNavLinkClass = (path) => {
    return currentPath === path ? 'active' : '';
  };

  return (
    <header>
      <img className="logo" src={logo} alt="Logo" />
      <button 
        className={`menu-toggle ${menuOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
        aria-label={menuOpen ? 'Close menu' : 'Open menu'}
      >
        <div></div>
        <div></div>
        <div></div>
      </button>
      <div className="nav-container">
        <nav>
          <ul>
            <li><a href="/" className={getNavLinkClass('/')}>Home</a></li>
            <li><a href="/astro-service" className={getNavLinkClass('/astro-service')}>Astrological Services</a></li>
            <li><a href="/pooja" className={getNavLinkClass('/pooja')}>Book Puja</a></li>
            <li><a href="/contact-us" className={getNavLinkClass('/contact-us')}>Contact Us</a></li>
          </ul>
        </nav>
        <div className={`menu ${menuOpen ? 'open' : ''}`}>
          <span className="close-btn" onClick={toggleMenu}>&times;</span>
          <nav>
            <ul>
              <li><a href="/" className={getNavLinkClass('/')}>Home</a></li>
              <li><a href="/astro-service" className={getNavLinkClass('/astro-service')}>Astrological Services</a></li>
              <li><a href="/pooja" className={getNavLinkClass('/pooja')}>Book Puja</a></li>
              <li><a href="/contact-us" className={getNavLinkClass('/contact-us')}>Contact Us</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
