import React from 'react';
import '../css/PrivacyPolicy.css'; // Ensure this path is correct

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <section className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p className='paragraph'>
                    Welcome to Cosmic Talk & Divvya Jyoti. This Privacy Policy describes how we collect, use, and share your personal information when you use our mobile application and website.
                </p>
                <h2>Information We Collect</h2>
                <ul>
                    <li><strong>Information You Provide Voluntarily</strong>: This includes your name, email address, and profile information that you provide to us.</li>
                    <li><strong>Information Automatically Collected</strong>: This includes device information, IP address, and usage data collected automatically when you use our app or website.</li>
                </ul>
                <h2>How We Use Your Information</h2>
                <ul>
                    <li><strong>To Provide and Maintain Our Services</strong>: Ensuring that our services are functioning correctly and meet your needs.</li>
                    <li><strong>To Improve and Personalize Our Services</strong>: Enhancing user experience and tailoring services to your preferences.</li>
                    <li><strong>To Communicate with You</strong>: Responding to your inquiries, sending you updates, and notifications.</li>
                    <li><strong>To Comply with Legal Obligations</strong>: Fulfilling legal requirements as necessary.</li>
                </ul>
                <h2>Sharing Your Information</h2>
                <ul>
                    <li><strong>With Service Providers</strong>: Who assist us in providing our services.</li>
                    <li><strong>With Law Enforcement Agencies or Government Authorities</strong>: If required by law.</li>
                    <li><strong>With Third Parties in Connection with a Business Transition</strong>: Such as a merger, acquisition, or sale of assets.</li>
                </ul>
                <h2>Security</h2>
                <p className='paragraph'>
                    We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.
                </p>
                <h2>Changes to This Policy</h2>
                <p className='paragraph'>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date. Your continued use of our services after any changes indicates your acceptance of the new terms.
                </p>
                <h2>User’s Consent</h2>
                <p className='paragraph'>
                    By accessing and using our website or application, you consent to the terms outlined in this Privacy Policy. If you do not agree with these terms, please do not use our services. Your continued use of our services signifies your unconditional consent to the terms and conditions of this Privacy Policy. This Privacy Policy should be read in conjunction with the Terms of Use or other terms and conditions provided on our website.
                </p>
                <h2>Contact Us</h2>
                <p className='paragraph'>
                    If you have any questions or concerns about our Privacy Policy, please contact us at <a href="./contact-us">contact us</a>.
                </p>
            </section>

            <section className="terms-and-conditions">
                <h1>Terms and Conditions</h1>
                <p className='paragraph'>
                    Welcome to Cosmic Talk & Divvya Jyoti. These Terms and Conditions govern your use of our mobile application and website. By accessing or using our services, you agree to comply with and be bound by these terms.
                </p>
                <h2>Use of Our Services</h2>
                <ul>
                    <li><strong>Eligibility</strong>: You must be at least 18 years old to use our services.</li>
                    <li><strong>Account Responsibility</strong>: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
                    <li><strong>Prohibited Activities</strong>: You agree not to engage in any illegal activities or use our services in a manner that could harm or disrupt the operation of our services.</li>
                </ul>
                <h2>Intellectual Property</h2>
                <p className='paragraph'>
                    All content and materials on our website and application are owned by or licensed to us and are protected by intellectual property laws. You may not reproduce, distribute, or otherwise use any content without our prior written consent.
                </p>
                <h2>Limitation of Liability</h2>
                <p className='paragraph'>
                    Our liability is limited to the fullest extent permitted by law. We are not responsible for any indirect, incidental, special, or consequential damages arising from your use of our services.
                </p>
                <h2>Termination</h2>
                <p className='paragraph'>
                    We reserve the right to terminate or suspend your access to our services at our sole discretion, without prior notice, for conduct that we believe violates these Terms and Conditions or is harmful to our services or other users.
                </p>
                <h2>Governing Law</h2>
                <p className='paragraph'>
                    These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts located in [Your Country/State].
                </p>
                <h2>Changes to Terms</h2>
                <p className='paragraph'>
                    We may update these Terms and Conditions from time to time. Any changes will be posted on this page with an updated date. Your continued use of our services constitutes your acceptance of the new terms.
                </p>
                <h2>Contact Us</h2>
                <p className='paragraph'>
                    If you have any questions about these Terms and Conditions, please contact us at <a href="./contact-us">contact us</a>.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
