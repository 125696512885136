import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/MandirDetails.css'; // Import your CSS file
import mandir_icon from '../image/mandir_icon.png';
import single from '../image/single_user.png';
import couple from '../image/couple.png';
import upto4 from '../image/4people.png';
import upto6 from '../image/6people.png';
import special from '../image/special.png';

const capitalizeEveryWord = (str) => {
  if (typeof str !== 'string') return ''; // Handle cases where str is not a string
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const HomeScreen = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);

  const location = useLocation();
  const { mandirId } = location.state || {};

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await fetch('https://www.divvyajyoti.com/api/mandirDetailsSlide.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mandirId }),
        });
        const data = await response.json();
        const urls = Object.values(data).map(fileName => `https://www.divvyajyoti.com/mandirSlide/${mandirId}/${fileName}?rand=${Math.random()}`);
        setImageUrls(urls);
      } catch (error) {
        console.error('Error fetching image list:', error);
      }
    };

    const fetchPujaDetails = async () => {
      try {
        const response = await fetch('https://www.divvyajyoti.com/api/pujaDetails.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mandirId }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch puja details');
        }
        const data = await response.json();
        setPujaDetails(data);
      } catch (error) {
        console.error('Error fetching puja details:', error);
      }
    };

    fetchImageUrls();
    fetchPujaDetails();

    const language = localStorage.getItem('language');
    setDefaultLanguage(language || 'en');
  }, [mandirId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % imageUrls.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [imageUrls.length]);
 const handlePress =()=>{
    window.open('https://play.google.com/store/apps/details?id=com.divvyajyoti&hl=en', '_blank');
}
  return (
    <div className="containerMandirDetails">
      <div className="swiperContainer">
        <div className="sliderMandir">
          <div className="prevControl" onClick={() => setCurrentSlide((prev) => (prev - 1 + imageUrls.length) % imageUrls.length)}>&#10094;</div>
          <div className="slidesMandir">
            {imageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Slide ${index}`}
                className={`imageMandirDetailsSlide ${index === currentSlide ? 'active' : ''}`}
              />
            ))}
          </div>
          <div className="nextControl" onClick={() => setCurrentSlide((prev) => (prev + 1) % imageUrls.length)}>&#10095;</div>
        </div>
      </div>

      {pujaDetails ? (
        <div>
          <h1 className="pujaName">{capitalizeEveryWord(pujaDetails.puja_name || '')}</h1>
          <div className="mandirInfo">
            <img src={mandir_icon} alt="Mandir" className="mandirImage" />
            <p className="mandirName">{capitalizeEveryWord(pujaDetails.mandir_name || '')}</p>
          </div>
          <div className="pujaPackages">
            <p className="packagesTitle">
              {defaultLanguage === 'en' ? 'Puja Packages' :
               defaultLanguage === 'ben' ? 'পূজা প্যাকেজসমূহ' :
               defaultLanguage === 'hindi' ? 'पूजा पैकेज' :
               'Puja Packages'}
            </p>
            <div className="packages">
              {pujaDetails.single && pujaDetails.single.trim() !== '' && pujaDetails.single !== '0' && (
                <div className="package" onClick={() => handlePress(1, 'Single', pujaDetails.single)}>
                  <img src={single} alt="Single" className="packageImage" />
                  <p className="packageText">
                    {defaultLanguage === 'en' ? 'Single' :
                     defaultLanguage === 'ben' ? 'একক' :
                     defaultLanguage === 'hindi' ? 'एकल' :
                     'Single'} 
                  </p>
                  <p className="packageAmount"> {'\u20B9'}{pujaDetails.single}</p>
                </div>
              )}

              {pujaDetails.couple && pujaDetails.couple.trim() !== '' && pujaDetails.couple !== '0' && (
                <div className="package" onClick={() => handlePress(2, 'Couple', pujaDetails.couple)}>
                  <img src={couple} alt="Couple" className="packageImage" />
                  <p className="packageText">
                   {defaultLanguage === 'en' ? 'Couple' :
                    defaultLanguage === 'ben' ? 'দম্পতি' :
                    defaultLanguage === 'hindi' ? 'युगल' :
                    'Couple'}
                  </p>
                  <p className="packageAmount"> {'\u20B9'}{pujaDetails.couple}</p>
                </div>
              )}

              {pujaDetails.upto4 && pujaDetails.upto4.trim() !== '' && pujaDetails.upto4 !== '0' && (
                <div className="package" onClick={() => handlePress(3, 'Upto4', pujaDetails.upto4)}>
                  <img src={upto4} alt="Up to 4" className="packageImage" />
                  <p className="packageText">
                   {defaultLanguage === 'en' ? 'Up to 4 Person' :
                    defaultLanguage === 'ben' ? '৪ জন পর্যন্ত' :
                    defaultLanguage === 'hindi' ? '4 व्यक्तियों तक' :
                    'Up to 4 Person'}
                  </p>
                  <p className="packageAmount"> {'\u20B9'}{pujaDetails.upto4}</p>
                </div>
              )}

              {pujaDetails.upto6 && pujaDetails.upto6.trim() !== '' && pujaDetails.upto6 !== '0' && (
                <div className="package" onClick={() => handlePress(4, 'Upto6', pujaDetails.upto6)}>
                  <img src={upto6} alt="Up to 6" className="packageImage" />
                  <p className="packageText">
                   {defaultLanguage === 'en' ? 'Up to 6 Person' :
                    defaultLanguage === 'ben' ? '৬ জন পর্যন্ত' :
                    defaultLanguage === 'hindi' ? '6 व्यक्तियों तक' :
                    'Up to 6 Person'}
                  </p>
                  <p className="packageAmount"> {'\u20B9'}{pujaDetails.upto6}</p>
                </div>
              )}

              {pujaDetails.special && pujaDetails.special.trim() !== '' && pujaDetails.special !== '0' && (
                <div className="package" onClick={() => handlePress(5, 'Special', pujaDetails.special)}>
                  <img src={special} alt="Special" className="packageImage" />
                  <p className="packageText">
                    {defaultLanguage === 'en' ? 'Special' :
                     defaultLanguage === 'ben' ? 'বিশেষ' :
                     defaultLanguage === 'hindi' ? 'विशेष' :
                     'Special'}
                  </p>
                  <p className="packageAmount"> {'\u20B9'}{pujaDetails.special}</p>
                </div>
              )}
            </div>
          </div>
          <p className="sectionTitle">
            {defaultLanguage === 'en' ? 'Benefits' :
             defaultLanguage === 'ben' ? 'সুবিধা' :
             defaultLanguage === 'hindi' ? 'फायदे' :
             'Benefits'}
          </p>
          <div className="benefits">
            {pujaDetails.benifit1 && <p className="benefit">i. {capitalizeEveryWord(pujaDetails.benifit1)}</p>}
            {pujaDetails.benifit2 && <p className="benefit">ii. {capitalizeEveryWord(pujaDetails.benifit2)}</p>}
            {pujaDetails.benifit3 && <p className="benefit">iii. {capitalizeEveryWord(pujaDetails.benifit3)}</p>}
            {pujaDetails.benifit4 && <p className="benefit">iv. {capitalizeEveryWord(pujaDetails.benifit4)}</p>}
            {pujaDetails.benifit5 && <p className="benefit">v. {capitalizeEveryWord(pujaDetails.benifit5)}</p>}
          </div>
          <p className="sectionTitle">
            {defaultLanguage === 'en' ? 'About Temple' :
             defaultLanguage === 'ben' ? 'মন্দির সম্পর্কে' :
             defaultLanguage === 'hindi' ? 'मंदिर के बारे में' :
             'About Temple'}
          </p>
          <p className="aboutTemple">{pujaDetails.aboutTemple}</p>
          <p className="sectionTitle">
            {defaultLanguage === 'en' ? 'About Puja' :
             defaultLanguage === 'ben' ? 'পূজা সম্পর্কে' :
             defaultLanguage === 'hindi' ? 'पूजा के बारे में' :
             'About Puja'}
          </p>
          <p className="aboutPuja">{pujaDetails.aboutPuja}</p>
          {pujaDetails.single && (
            <div className="confirm" onClick={() => handlePress(1, 'Single', pujaDetails.single)}>
              <p className="confirmText">
                {defaultLanguage === 'en' ? `Pooja starting from ₹` :
                 defaultLanguage === 'ben' ? `পূজা শুরু হচ্ছে ₹` :
                 defaultLanguage === 'hindi' ? `पूजा शुरू हो रही है ₹` :
                 `Pooja starting from ₹`}
                {pujaDetails.single}
              </p>
            </div>
          )}
        </div>
      ) : (
        <p>Loading puja details...</p>
      )}
    </div>
  );
};

export default HomeScreen;
