import React, { useState, useEffect } from 'react';
import '../css/Home.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import interact from '../image/interaction.png';
import call from '../image/video-call.png';
import puja from '../image/puja.png';
function Home() {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [astrologers, setAstrologers] = useState([]);

  const API_URL = 'https://www.divvyajyoti.com/api/slideHomePc.php';
  const BASE_URL = 'https://www.divvyajyoti.com/homeSlidePc/';
  const navigate = useNavigate();

  useEffect(() => {
    fetch(API_URL)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log("Fetched data:", data);
        if (Array.isArray(data)) {
          const imageUrls = data.map(filename => `${BASE_URL}${filename}`);
          console.log("Image URLs:", imageUrls);
          setImages(imageUrls);
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching images:', error));
  }, [API_URL, BASE_URL]);

  useEffect(() => {
    if (images.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  useEffect(() => {
    fetch('https://www.divvyajyoti.com/api/onlineAstrologerHomePc.php')
      .then(response => response.json())
      .then(data => setAstrologers(data))
      .catch(error => console.error('Error fetching astrologers:', error));
  }, []);

  const handleCardClick = (phoneNumber) => {
    navigate('/astrologer-profile', { state: { phone_number: phoneNumber } });
  };

  const handleBoxClick = (path) => {
    navigate(path); // Navigate to the given path
  };
  return (
    <div className='homeCointainer'>
      <div className='homeBanner'>
        <div className="slideshow-container">
          <img 
            className='imgHome'
            src={images[currentIndex]} 
            alt="Slideshow" 
            onError={(e) => {
              console.error('Error loading image:', e.target.src);
              e.target.src = 'https://via.placeholder.com/600x200?text=Image+Not+Found'; // Fallback image
            }}
          />
         
        </div>
        <div className='contactHome'>
          <div className='boxHome' onClick={() => handleBoxClick('/astro-service')}>
              <img className='boxImageHome' src={interact} alt='interact iamge'/>
              <p className='titleBoxImage'>Chat with Astrologer</p>
          </div>

          <div className='boxHome' onClick={() => handleBoxClick('/astro-service')} >
          <img className='boxImageHome' src={call} alt='call iamge'/>
            <p className='titleBoxImage'>Talk to Astrologer</p>
           </div>
          <div className='boxHome' onClick={() => handleBoxClick('/pooja')}>
          <img className='boxImageHome' src={puja} alt='=puja iamge'/>
            <p className='titleBoxImage'>Book a Pooja</p>
          </div> 
             
        </div>
      </div>
      <section id="about-us">
        <Helmet>
          <title>Home | Divvya Jyoti Astrology Consulting</title>
          <meta name="description" content="Divvya Jyoti offers expert astrology consultations and spiritual guidance. Learn more about our services, team, and unique mobile app for connecting with renowned astrologers." />
          <meta name="keywords" content="Divvya Jyoti, astrology consulting, spiritual guidance, Vedic astrology, Western astrology, Chinese astrology, mobile app, puja services, horoscopes" />
          <meta property="og:title" content="About Us | Divvya Jyoti" />
          <meta property="og:description" content="Discover how Divvya Jyoti connects seekers with profound wisdom through expert astrology consultations and spiritual guidance. Learn more about our services and unique mobile app." />
          <meta property="og:image" content="/path-to-image.jpg" />
          <meta property="og:url" content="https://yourwebsite.com/about-us" />
        </Helmet>

        <h2>About Us</h2>
        <p className="introHome"> 
          At Divvya Jyoti, we transcend the boundaries of time and space to connect seekers with the profound wisdom of astrology. Our esteemed consulting company is dedicated to providing unparalleled spiritual guidance and insights to individuals seeking clarity, empowerment, and enlightenment.
        </p>
        <p className="mission">
          As a pioneering force in the realm of spiritual consultancy, Divvya Jyoti stands as a beacon of trust and authenticity. Our team comprises renowned astrologers who possess years of experience and expertise in various branches of astrology, including Vedic, Western, and Chinese astrology. With their profound insights and intuitive abilities, they delve deep into the cosmic realms to unravel the mysteries of destiny, karma, and celestial influences.
        </p>
        <p className="services">
          At Divvya Jyoti, we understand that each individual is unique, with distinct aspirations, challenges, and destinies. Therefore, we offer personalized consultations tailored to meet the specific needs and concerns of our clients. Whether you're grappling with career dilemmas, relationship issues, health concerns, or seeking spiritual enlightenment, our astrologers provide compassionate guidance and practical solutions to navigate life's journey with clarity and confidence.
        </p>
        <p className="unique">
          What sets Divvya Jyoti apart is our commitment to accessibility and convenience. Through our innovative mobile app, seekers can effortlessly connect with our esteemed astrologers from the comfort of their homes. Whether you prefer a one-on-one call, a text-based chat, or a face-to-face video consultation, our platform offers flexible options to suit your preferences and schedule.
        </p>
        {/* Uncomment this section if needed */}
        {/* <p className="resources">
          In addition to personalized consultations, Divvya Jyoti offers a plethora of resources, including daily horoscopes and astrological articles, empowering individuals to deepen their understanding of astrology and its profound impact on their lives. Embark on a transformative journey of self-discovery and spiritual enlightenment with Divvya Jyoti. Join our community of seekers today and unlock the secrets of the universe with the guidance of our seasoned astrologers. Your destiny awaits!
        </p> */}
        <p className="puja">
          Additionally, we offer puja services in various Hindu temples across India.
        </p>
      </section>
      
      <div className="astrologer-container">
        <h1 className='titleHeading'>Online Astrologers</h1>
        <h3 className='titleSubHeading'>Best Astrologers from India for Online Consultation</h3>
        {astrologers.length > 0 ? (
          <div className="astrologer-scroll-container">
            {astrologers.map((astrologer, index) => (
              <div
                key={index}
                className="astrologer-card1"
                onClick={() => handleCardClick(astrologer.phone_number)}
              >
                <img
                  src={`https://www.divvyajyoti.com/astrologerDp/${astrologer.dp_url}`}
                  alt={astrologer.dp_name || astrologer.name}
                  className="astrologer-image"
                />
                <h2 className="astrologer-name">
                  {astrologer.dp_name || astrologer.name}
                </h2>
              </div>
            ))}
          </div>
        ) : (
          <p>No astrologers available at the moment</p>
        )}
        <div className='viewAll'>
          <a href="./astro-service" style={{textDecoration:'none',color:'darkgreen',alignSelf:'flex-end'}}>View All</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
