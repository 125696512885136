import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import AstroService from './pages/AstroServices';
import Pooja from './pages/Pooja';
import ContactUs from './pages/ContactUs';
import AstrologerProfile from './pages/AstrologerProfile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MandirDetails from './pages/MandirDetails';
import RefundPolicy from './pages/RefundPolicy';

function App() {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <main style={{ flex: 1, padding: '0px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/astro-service" element={<AstroService />} />
            <Route path="/pooja" element={<Pooja />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/astrologer-profile" element={<AstrologerProfile />} />
            <Route path="/mandir-details" element={<MandirDetails />} />
            <Route path="/PRIVACY-POLICY.html" element={<PrivacyPolicy />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
