import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../css/Pooja.css'; // Import your CSS file
import mandirIcon from '../image/mandir_icon.png';
import { Helmet } from 'react-helmet';
const capitalizeEveryWord = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Pooja = () => {
  const navigate = useNavigate();
  const [mandir, setMandir] = useState([]);

  useEffect(() => {
    fetchMandirList();
  }, []);

  const fetchMandirList = async () => {
    try {
      const response = await fetch('https://www.divvyajyoti.com/api/pujaList.php');
      const data = await response.json();
      setMandir(data);
      console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

 

  const handleMandirPress = (selectedMandir) => {
    navigate('/mandir-details', { state: { mandirId: selectedMandir.id, date: selectedMandir.puja_date } });
  };

  const formatDate = (pujaDate) => {
    const date = new Date(pujaDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[month - 1];
    return `${day} ${monthName}`;
  };

  return (
    <div className="containerPooja">

<Helmet>
        <title>Pooja | Divvya Jyoti</title>
        <meta name="description" content="Divvya Jyoti offers expert astrology consultations and spiritual guidance. Learn more about our services, team, and unique mobile app for connecting with renowned astrologers." />
        <meta name="keywords" content="Divvya Jyoti, astrology consulting, spiritual guidance, Vedic astrology, Western astrology, Chinese astrology, mobile app, puja services, horoscopes" />
        <meta property="og:title" content="About Us | Divvya Jyoti" />
        <meta property="og:description" content="Discover how Divvya Jyoti connects seekers with profound wisdom through expert astrology consultations and spiritual guidance. Learn more about our services and unique mobile app." />
        <meta property="og:image" content="/path-to-image.jpg" /> {/* Adjust this path to your image */}
        <meta property="og:url" content="https://yourwebsite.com/about-us" /> {/* Update with your actual URL */}
      </Helmet>

      <h2 className="title">
        ~: Upcoming Tithi :~
      </h2>

      <ul className="mandir-list">
        {mandir.map((item) => (
          <li key={item.id} onClick={() => handleMandirPress(item)} className="mandir-item">
            <div className="date-tag">
              <span>{formatDate(item.puja_date)}</span>
            </div>
            <h3 className="puja-name">{capitalizeEveryWord(item.puja_name)}</h3>
            <img src={`https://www.divvyajyoti.com/mandirImage/${item.image_url}`} alt={item.puja_name} className="image-mandir" />
            <p className="description">{capitalizeEveryWord(item.description)}</p>
            <div className="mandir-details">
              <img src={mandirIcon} alt="Mandir" className="mandir-icon" />
              <span className="mandir-name">{capitalizeEveryWord(item.mandir_name)}</span>
            </div>
            <div className="book-now">
              <span>Book Now</span>
            </div>
          </li>
        ))}
      </ul>

 
    </div>
  );
};

export default Pooja;
