import React from 'react';
import '../css/RefundPolicy.css';

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <h1>Return and Refund Policy</h1>
      <p>Welcome to Divvyajoti (a unit of DIVYASURYA ENTERPRISE LLP).</p>
      
      <h2>Refund Policy</h2>
      <p>
        Please note that once money is added to your account or paid for a service,
        it is non-refundable. We strive to provide the best service and experience, but
        due to the nature of our services and products, refunds cannot be processed.
      </p>

      <h2>Return Policy</h2>
      <p>
        For puja-related items, such as prasad, once delivered, they cannot be returned
        or replaced. We take great care in the preparation and delivery of these items to
        ensure they meet our quality standards. Once the items are delivered, they are
        considered final, and no returns or replacements will be accepted.
      </p>
      
      <p>If you have any questions about our policies, please contact our support team through Mobile App.</p>
    </div>
  );
};

export default RefundPolicy;
